<template>
  <h3>
    View the Anvilsong Setting documents here:
  </h3>

  <h4 @click="toggleSection('dwarves')">Dwarves</h4>
  <div v-if="visibleSections.dwarves">
    <div><a href="/anvilsong_files/txt/dwarf_summary.html">Dwarven Summary</a></div>
    <div><a href="/anvilsong_files/txt/stone_king_dwarven_perspective.html">Stone King (Dwarven Perspective)</a></div>

  </div>

  <h4 @click="toggleSection('elves')">Elves</h4>
  <div v-if="visibleSections.elves">
    <a href="/anvilsong_files/txt/elf_summary.html">Elven Summary</a>
  </div>

  <h4 @click="toggleSection('halflings')">Halflings</h4>
  <div v-if="visibleSections.halflings">
    <a href="/anvilsong_files/txt/halfling_summary.html">Halfling Summary</a>
  </div>

  <h4 @click="toggleSection('humans')">Humans</h4>
  <div v-if="visibleSections.humans">
    <a href="/anvilsong_files/txt/human_summary.html">Human Summary</a>
  </div>

  <h4 @click="toggleSection('orcs')">Orcs, Goblins, Trolls</h4>
  <div v-if="visibleSections.orcs">
    <p>No links available yet.</p>
  </div>

  <h4 @click="toggleSection('ogres')">Ogres, Giants</h4>
  <div v-if="visibleSections.ogres">
    <p>No links available yet.</p>
  </div>

  <h4 @click="toggleSection('references')">Setting References</h4>
  <div v-if="visibleSections.references">
    <p>No links available yet.</p>
  </div>

  <h4 @click="toggleSection('stories')">Stories</h4>
  <div v-if="visibleSections.stories">
    <div><a href="/anvilsong_files/stories/whitecliffs_2.html">Whitecliff Sanctuary</a></div>
    <div><a href="/anvilsong_files/stories/eilif.html">The Grand Library</a></div>
    <h4 @click="toggleSection('kids_stories')">Wolfgar's Unit</h4>
    <div v-if="visibleSections.kids_stories">
      <div><a href="/anvilsong_files/stories/wolfgar_holafiel.html">Wolfgar and Holafiel</a></div>
      <div><a href="/anvilsong_files/stories/elgolar.html">Elgolar</a></div>
      <div><a href="/anvilsong_files/stories/haradai_finola.html">Haradai and Finola</a></div>
      <div><a href="/anvilsong_files/stories/korra.html">Kora</a></div>
      <div><a href="/anvilsong_files/stories/raya.html">Raya</a></div>
      <div><a href="/anvilsong_files/stories/sverre.html">Sverre</a></div>
      <br>
      <div><a href="/anvilsong_files/stories/stacks.html">The Stacks</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Anvilsong_View',
  data() {
    return {
      visibleSections: {
        dwarves: false,
        elves: false,
        halflings: false,
        humans: false,
        orcs: false,
        ogres: false,
        references: false,
        stories: false,
        kids_stories: false
      }
    };
  },
  methods: {
    toggleSection(section) {
      this.visibleSections[section] = !this.visibleSections[section];
    }
  },
  mounted() {
    console.log('Anvilsong view is mounted');
    this.$store.commit("set_page", 'anvilsong');
  }
};
</script>

<style scoped>
h4 {
  cursor: pointer;
  color: #2c3e50;
}

h4:hover {
  color: #1a5276;
}

div {
  margin-left: 20px;
}
</style>
